import React from 'react';
import walletActiveIcon from '../assets/sidebaricons/walletActive.png'
import walletInActiveIcon from '../assets/sidebaricons/walletInActive.png'

const WalletLogo = (location) => {
    return (
        <img src={location.location === "/wallet" ? walletActiveIcon : walletInActiveIcon} className={location.location === "/wallet" ? "sideBarIcon" : "sideBarIcon-not-selected"} alt='' />
    )
}

export default WalletLogo