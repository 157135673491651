import React, { forwardRef } from "react";
const ReadOnlyInput = forwardRef(({ value, onClick }, ref) => (
    <input
        value={value}
        onClick={onClick}
        ref={ref}
        readOnly 
    />
));

export default ReadOnlyInput;