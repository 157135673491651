// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pilots_map_view_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}

.pilots_map_view_wrapper .heading {
  font-size: 20px;
  font-weight: 700;
  color: #3b3b3b;
}
`, "",{"version":3,"sources":["webpack://./src/features/MyRequests/SubComponents/MapViewPilotLocation/ViewPilotHistoryOnMap.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;AAChB","sourcesContent":[".pilots_map_view_wrapper {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  gap: 0.2rem;\n}\n\n.pilots_map_view_wrapper .heading {\n  font-size: 20px;\n  font-weight: 700;\n  color: #3b3b3b;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
