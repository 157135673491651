import React, { useEffect, useRef, useState } from 'react'
import MainContentWrapper from '../../Components/MainContentWrapper'
import Popup from '../../ReusableFunctions/Popup'
import Loading from '../../ReusableFunctions/Loader'
import ViewDSP from './SubComponents/ViewDSP'
import { getAllDSPs } from '../../apis/adminAPIs/dsp'
import Search from '../../ReusableComponents/Search'
import TableView from '../../ReusableComponents/TableView/TableView'
import _debounce from 'lodash/debounce';
import DspFilter from './SubComponents/DspFilter'
import Filter from "../../assets/filter.svg"
import { Button } from '../../Components/Helper'
import { generateCSV } from '../../utils/generateAndDownloadCsv'
import { PDRL_ADMIN_ROUTES } from '../../routes'
import { useNavigate } from 'react-router'
import { useSelector } from 'react-redux'

const PAGE_SIZE = 20

const csvHeader = [
    { label: "Company Name", key: "dsp_name" },
    { label: "Company Address", key: "dsp_address" },
    { label: "Owner Name", key: "operator_name" },
    { label: "Owner Email", key: "operator_email" },
    { label: "Date Of Added", key: "dsp_date_created", isDate: true },
    { label: "Status", key: "status" },

]

const DroneServiceProvider = () => {
    const user = useSelector(state => state.user?.user)
    const navigate = useNavigate();
    const isFirstRender = useRef(true);
    const [loading, setLoading] = useState()
    const [selectedDSP, setSelectedDSP] = useState()
    const [serviceProviders, setServiceProviders] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [searchString, setSearchString] = useState("")
    const [dspsCount, setDspsCount] = useState(0)
    const [filter, setFilter] = useState({
        fromDate: null,
        toDate: null,
        isActive: true
    })
    const [openFilter, setOpenFilter] = useState(false)

    const getAllDSPsFunc = async () => {
        try {
            setLoading(true)
            const { fromDate, toDate, isActive } = filter
            const dsps = await getAllDSPs(PAGE_SIZE, currentPage, searchString || '', fromDate, toDate, isActive)
            setServiceProviders(dsps.dsps)
            setDspsCount(dsps.count)
            setLoading(false)
            Loading.set(false)
        } catch (err) {
            setLoading(false)
            Loading.set(false)
            Popup.alert("Error", err, 'ERROR')
        }
    }

    const downloadCSV = async () => {
        Loading.set(true)
        const { fromDate, toDate, isActive } = filter
        const dsps = await getAllDSPs(PAGE_SIZE, currentPage, searchString || '', fromDate, toDate, isActive)
        const data = dsps.dsps.map((d) => {
            if (d.dsp_is_active) {
                return {
                    ...d,
                    status: "Active"
                }
            }
            else {
                return {
                    ...d,
                    status: "inActive"
                }
            }
        })
        generateCSV(csvHeader, data, "output")
        Loading.set(false)
    }

    useEffect(() => {
        Loading.set(true)
        setLoading(true)
        getAllDSPsFunc()
    }, [])

    useEffect(() => {
        Loading.set(true)
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }

        const debouncedGetAllDSPs = _debounce(() => {
            getAllDSPsFunc();
        }, 3000);

        debouncedGetAllDSPs();

        return () => {
            debouncedGetAllDSPs.cancel();
        };
    }, [searchString, currentPage, filter.fromDate, filter.toDate, filter.isActive]);

    return (
        <MainContentWrapper
            header={`Drone service providers (${dspsCount})`}
            rightContent={
                <div style={{ display: "flex", gap: '30px' }}>
                    <Search
                        searchValue={searchString}
                        onChange={(value) => {
                            setLoading(true);
                            setSearchString(value)
                            setCurrentPage(1)
                        }}
                    />
                    <Button
                        innerClassName={"filter-btn-inner"}
                        iconStyle={{ width: "18px", marginRight: "10px" }}
                        text={"Filter"}
                        icon={Filter}
                        className={"filter-btn"}
                        onClick={() => {
                            setOpenFilter(true)
                        }}
                        textClassName={"user-details-filter-text"}
                    />
                    {/* <Button
                        text={"Export CSV"}
                        style={{ width: "104px", height: "38px", fontSize: "15px" }}
                        onClick={() => {
                            downloadCSV()
                        }}
                    /> */}
                </div>
            }
        >
            {selectedDSP &&
                <ViewDSP
                    open={selectedDSP}
                    onClose={() => {
                        setSelectedDSP()
                        localStorage.setItem('dsp_id', "admin")
                    }}
                    dsp={selectedDSP}
                    callback={(dsp) => {
                        setServiceProviders(prev => prev.map(d => {
                            if (d.dsp_id === dsp.dsp_id) return dsp;
                            else return d
                        }))
                        localStorage.setItem('dsp_id', dsp.dsp_id)
                        setSelectedDSP(dsp)
                    }}
                />
            }

            {openFilter &&
                <DspFilter
                    filter={filter}
                    onFilterChange={(filter) => {
                        setCurrentPage(1)
                        setOpenFilter(false)
                        setFilter(filter)
                    }}
                    onClose={() => setOpenFilter(false)}
                    open={openFilter}
                />
            }

            <TableView
                isLoading={loading}
                tableContainerHeight={dspsCount < 15 ? "calc(100% - 40px)" : "calc(100% - 65px)"}
                showSrNo
                onStateChange={(changedState) => {
                    setLoading(true);
                    setCurrentPage(changedState.pageNumber)
                }}
                onClickRow={(dsp) => {
                    localStorage.setItem("dsp_id", dsp.dsp_id)
                    setSelectedDSP(dsp)
                }}
                onClickLink={(request) => { }}
                columns={[
                    { Header: "Company Name", accessor: "dsp_name", width: "10%" },
                    { Header: "Company Address", accessor: "dsp_address", width: "10%" },
                    { Header: "Owner Name", accessor: "operator_name", width: "10%" },
                    { Header: "Owner Email", accessor: "operator_email", width: "10%" },
                    { Header: "Date Of Added", accessor: "dsp_date_created", width: "10%", isDate: "true" },
                    { Header: "Status", accessor: "dsp_is_active_label", width: "5%", showColor: true },
                ]}
                data={serviceProviders.map(sp => {
                    return {
                        ...sp,
                        dsp_is_active_label: sp.dsp_is_active ? "Active" : "Inactive",
                        color: sp.dsp_is_active ? 'green' : 'red'
                    }
                })}
                count={dspsCount}
                pageNumber={currentPage}
                pageSize={PAGE_SIZE}
            />
        </MainContentWrapper>
    )
}

export default DroneServiceProvider