// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customr_input_date {
    border: 1px solid var(--BORDER_COLOR);
    padding: 5px 12px;
    width: 100%;
    background-color: #F3F3F3;
    font-size: 14px;
    gap: 25px;
    border: 1px solid #D9D9D9;
    border-radius: 4px;
}

.input_field {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: start;
}

.input_field label {
    font-size: 14px;
    color: #3B3B3B;
}`, "",{"version":3,"sources":["webpack://./src/features/Coupon/AddCouponFrom.css"],"names":[],"mappings":"AAAA;IACI,qCAAqC;IACrC,iBAAiB;IACjB,WAAW;IACX,yBAAyB;IACzB,eAAe;IACf,SAAS;IACT,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,QAAQ;IACR,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,cAAc;AAClB","sourcesContent":[".customr_input_date {\n    border: 1px solid var(--BORDER_COLOR);\n    padding: 5px 12px;\n    width: 100%;\n    background-color: #F3F3F3;\n    font-size: 14px;\n    gap: 25px;\n    border: 1px solid #D9D9D9;\n    border-radius: 4px;\n}\n\n.input_field {\n    display: flex;\n    flex-direction: column;\n    gap: 8px;\n    align-items: start;\n}\n\n.input_field label {\n    font-size: 14px;\n    color: #3B3B3B;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
