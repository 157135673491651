import React, { useEffect, useRef } from 'react'
import { GLOBAL_STYLES } from '../../../../appConstants';

const MorePopup = ({ open, onClose,style,anchorStyle, children }) => {

    const popupRef = useRef(null);

    const handleClickOutside = (event) => {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
            onClose();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    return (
        <div ref={popupRef} style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB, border: '1px solid #ccc', padding: '10px', position: 'absolute', background: "White", width: "200px", borderRadius: "5px", boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.15)",zIndex:2,...style }}>
            {/* anchor */}
            <div
                style={{
                    position: 'absolute',
                    top: '-6px',
                    left: '10px',
                    width: '10px',
                    height: '10px',
                    background: "#fff",
                    borderTop: "1px solid #ccc",
                    borderLeft: "1px solid #ccc",
                    transform: "rotate(45deg)",
                    ...anchorStyle
                }}
            />
            {children}
        </div>
    );
}

export default MorePopup